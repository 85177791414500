import { z } from 'zod';

/** Education file schema. */
export const educationFileSchema = z.object({

	/** ID. */
	id: z.number(),

	/** URL. */
	url: z.string(),

	/** Name. */
	name: z.string(),
});

/** Education file. */
export type EducationFile = Readonly<z.infer<typeof educationFileSchema>>;

/** Education file create/update schema. */
export const educationFileCreateUpdateSchema = educationFileSchema.pick({
	url: true,
	name: true,
});

/** Education file create/update. */
export type EducationFileCreateUpdate = Readonly<z.infer<typeof educationFileCreateUpdateSchema>>;
