import { z } from 'zod';

import { userAddressSchema, userPracticeAddressSchema } from './user-address';
import { educationSchema } from './education';
import { trainingSchema } from './training';
import { credentialSchema } from './credentials/credential';
import { twoFactorMethodSchema } from './two-factor/two-factor-method';
import { userRatingSchema } from './user-rating';

/** User schema. */
export const userSchema = z.object({
	id: z.number(),
	avatarUrl: z.string(),
	backgroundUrl: z.string(),
	firstName: z.string(),
	lastName: z.string(),
	preferredName: z.string().nullable(),
	initials: z.string().nullable(),
	credentials: z.string(),
	username: z.string(),
	entity: z.string(),
	pronoun: z.string(),
	clinician: z.string(),
	graduationDate: z.date().nullable(),
	email: z.string(),
	secondaryEmail: z.string(),
	phoneNumber: z.string(),
	address: userAddressSchema,
	practiceAddress: userPracticeAddressSchema,
	fax: z.string(),
	description: z.string(),
	specialty: z.array(z.string()),
	specialtyArea: z.string(),
	totalContacts: z.number(),
	hasContact: z.boolean(),
	createdDate: z.date(),
	educations: z.array(educationSchema),
	trainings: z.array(trainingSchema),
	documents: z.array(credentialSchema),
	npiNumber: z.string(),
	twoFactorAuth: twoFactorMethodSchema.nullable(),
	isStudent: z.boolean(),
	allowNotifications: z.boolean(),
	rating: userRatingSchema.nullable(),
	isAvailableForPages: z.boolean(),
	baaFile: z.string().nullable(),
});

/** User update schema. */
export const userUpdateSchema = userSchema
	.pick({
		avatarUrl: true,
		firstName: true,
		lastName: true,
		preferredName: true,
		initials: true,
		credentials: true,
		username: true,
		entity: true,
		pronoun: true,
		clinician: true,
		graduationDate: true,
		address: true,
		practiceAddress: true,
		fax: true,
		description: true,
		specialty: true,
		specialtyArea: true,
		backgroundUrl: true,
		npiNumber: true,
		isStudent: true,
		allowNotifications: true,
		isAvailableForPages: true,
	})
	.partial();

/** User. */
export type User = Readonly<z.infer<typeof userSchema>>;

/** User update data. */
export type UserUpdateData = Readonly<z.infer<typeof userUpdateSchema>>;

export namespace User {

	/** User's full name shortcut type.  */
	export type FullName = Pick<User, 'firstName' | 'lastName' | 'preferredName' | 'initials'>;

	/**
		* To readable user's full name.
		* @param param0 Object with full name data.
		*/
	export function toReadableFullName({ firstName, lastName, preferredName, initials }: FullName): string {
		const nameParts = [
			...(preferredName ? [preferredName] : [firstName]),
			...(initials ? [initials] : []),
			lastName,
		] as const;

		return nameParts.join(' ');
	}

	/**
		* Compare two users.
		* @param user User.
		* @param anotherUser Another user.
		*/
	export function compare(user?: User, anotherUser?: User): boolean {
		return user?.id === anotherUser?.id;
	}
}
