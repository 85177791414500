import { z } from 'zod';

import { DateUtils } from '../utils/date-utils';

/** Training schema. */
export const trainingSchema = z.object({
	/** ID. */
	id: z.number(),

	/** Training program. */
	program: z.string(),

	/** School. */
	school: z.string(),

	/** Hospital system. */
	hospitalSystem: z.string(),

	/** Specialty. */
	specialty: z.string(),

	/** Date. */
	date: z.date(),
});

/** Training. */
export type Training = Readonly<z.infer<typeof trainingSchema>>;

/** Training create/update schema. */
export const trainingCreateUpdateSchema = trainingSchema.pick({
	program: true,
	school: true,
	hospitalSystem: true,
	specialty: true,
	date: true,
});

/** Training create/update. */
export type TrainingCreateUpdate = Readonly<z.infer<typeof trainingCreateUpdateSchema>>;

export namespace Training {

	/**
	 * A function that determines the order of the elements.
	 * @param training1 First training.
	 * @param training2 Second training.
	 */
	export function compareFn(training1: Training, training2: Training): number {
		return DateUtils.compare(training1.date, training2.date);
	}
}
