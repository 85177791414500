import { z } from 'zod';

/** User's address schema. */
export const userAddressSchema = z.object({
	city: z.string(),
	state: z.string(),
}).readonly();

/** User's practice address schema. */
export const userPracticeAddressSchema = z.object({
	state: z.string(),
	zipCode: z.string(),
}).readonly();

/** User's address. */
export type UserAddress = z.infer<typeof userAddressSchema>;

/** User's practice address. */
export type UserPracticeAddress = z.infer<typeof userPracticeAddressSchema>;

export namespace UserAddress {

	/**
	 * Convert user's address to human-readable string.
	 * @param address User's address.
	 */
	export function toString(address: UserAddress | UserPracticeAddress): string {
		return Object
			.values(address)
			.filter(value => value !== '')
			.join(', ');
	}
}
