import { z } from 'zod';

import { credentialTypeSchema } from './credential-type';

/** Credential schema. */
export const credentialSchema = z.object({

	/** ID. */
	id: z.number(),

	/** Type. */
	type: credentialTypeSchema,

	/** Issued on date. */
	issuedOnDate: z.date().nullish(),

	/** Expires on date. */
	expiresOnDate: z.date().nullish(),

	/** Status. */
	status: z.string().nullish(),

	/** Certification number. */
	certificationNumber: z.string().nullish(),

	/** License number. */
	licenseNumber: z.string().nullish(),

	/** Registration number. */
	registrationNumber: z.string().nullish(),

	/** Policy number. */
	policyNumber: z.string().nullish(),

	/** Certification board. */
	certificationBoard: z.string().nullish(),

	/** State. */
	state: z.string().nullish(),

	/** File URL. */
	file: z
		.string()
		.min(1)
		.nullable(),

	/** Insurance carrier. */
	insuranceCarrier: z.string().nullish(),

	/** Coverage. */
	coverage: z.string(),

	/** Effective date. */
	effectiveDate: z.date().nullish(),
});

/** Create credential schema. */
export const createCredentialSchema = credentialSchema.omit({ id: true });

/** Credential. */
export type Credential = Readonly<z.infer<typeof credentialSchema>>;

/** Create credential. */
export type CredentialCreate = Readonly<z.infer<typeof createCredentialSchema>>;
