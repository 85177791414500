import { z } from 'zod';

import { DateUtils } from '../utils/date-utils';

import { educationFileCreateUpdateSchema, educationFileSchema } from './education-file';

/** Education schema. */
export const educationSchema = z.object({
	/** ID. */
	id: z.number(),

	/** Education program. */
	program: z.string(),

	/** School. */
	school: z.string(),

	/** City. */
	city: z.string(),

	/** State. */
	state: z.string(),

	/** Date of completion. */
	dateOfCompletion: z.date(),

	/** Education major. */
	major: z.string(),

	/** Degree certificate earned. */
	degreeCertificateEarned: z.string(),

	/** Files. */
	files: z.array(educationFileSchema),
});

/** Education. */
export type Education = Readonly<z.infer<typeof educationSchema>>;

/** Education create/update schema. */
export const educationCreateUpdateSchema = educationSchema
	.pick({
		program: true,
		school: true,
		city: true,
		state: true,
		dateOfCompletion: true,
		major: true,
		degreeCertificateEarned: true,
	})
	.extend({
		files: z.array(educationFileCreateUpdateSchema),
	});

/** Education create/update. */
export type EducationCreateUpdate = Readonly<z.infer<typeof educationCreateUpdateSchema>>;

export namespace Education {

	/**
	 * A function that determines the order of the elements.
	 * @param education1 First education.
	 * @param education2 Second education.
	 */
	export function compareFn(education1: Education, education2: Education): number {
		return DateUtils.compare(education1.dateOfCompletion, education2.dateOfCompletion);
	}
}
