import { z } from 'zod';

/** Schema to validate user rating value. */
export const userRatingSchema = z.object({

	/** Average rating. */
	averateRating: z.number(),

	/** Number of rated consultations given by a user. */
	ratedConsultationsAmount: z.number(),
});

/** User rating. */
export type UserRating = z.infer<typeof userRatingSchema>;
